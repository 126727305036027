<template><div><h1 id="supporting-the-docs" tabindex="-1"><a class="header-anchor" href="#supporting-the-docs"><span>Supporting the Docs</span></a></h1>
<p>Want to help support the docs? Well there's some ways you can help!</p>
<h2 id="contributing-via-issues" tabindex="-1"><a class="header-anchor" href="#contributing-via-issues"><span>Contributing via Issues</span></a></h2>
<p>Contributing via Issues is pretty simple but there are some rules:</p>
<ul>
<li>Reserve the issues tab exclusively for documentation-related matters; refrain from discussing personal support issues or OS installation problems there.</li>
<li>When reporting a typo or suggesting better clarification, please specify the page where the issue is found. It would be helpful to avoid searching extensively for these problems.</li>
</ul>
<h2 id="contributing-via-prs" tabindex="-1"><a class="header-anchor" href="#contributing-via-prs"><span>Contributing via PRs</span></a></h2>
<p>Some guidelines when contributing via PRs:</p>
<ul>
<li>Use your brain (please).</li>
<li>Proofread your submissions.</li>
<li>Pull Requests may be rejected if they do not align with the docs's content or if they contain inaccurate information. Generally, we will provide reasons for rejection or request revisions.
<ul>
<li>Additionally, it would be appreciated if you can provide sources for significant commits. This helps us verify the validity of the information you provide.</li>
</ul>
</li>
<li>Ensure that the pages adhere to the markdown style used in the docs.</li>
</ul>
<h2 id="how-to-contribute" tabindex="-1"><a class="header-anchor" href="#how-to-contribute"><span>How to Contribute</span></a></h2>
<p>The best way to test your commits and ensure proper formatting is by forking the repository, making and testing changes locally, then contributing with a pull request.</p>
<h3 id="simple-step-by-step-guide" tabindex="-1"><a class="header-anchor" href="#simple-step-by-step-guide"><span>Simple step-by-step guide</span></a></h3>
<ul>
<li>Install NodeJS
<ul>
<li>You can download NodeJS from <a href="https://nodejs.org/en/download" target="_blank" rel="noopener noreferrer">the NodeJS download page</a>.</li>
</ul>
</li>
<li><a href="https://github.com/chrultrabook/docs/fork/" target="_blank" rel="noopener noreferrer">Fork this repo</a></li>
<li>Clone your fork, open a terminal and <code v-pre>cd</code> to it</li>
<li>Run the <code v-pre>npm i</code> command, then <code v-pre>npm run dev</code></li>
<li>Visit <code v-pre>localhost:8080</code> in your web browser and preview your changes.</li>
</ul>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>Vuepress will automatically regenerate pages when you make changes.</p>
</div>
<h2 id="tips" tabindex="-1"><a class="header-anchor" href="#tips"><span>Tips</span></a></h2>
<p>Some tools that make contributing a bit easier:</p>
<ul>
<li><a href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">Visual Studio Code</a></li>
<li><a href="https://typora.io/" target="_blank" rel="noopener noreferrer">Typora</a> for real time markdown rendering.</li>
<li><a href="https://macromates.com/" target="_blank" rel="noopener noreferrer">TextMate</a> for easy and powerful mass find/replace.</li>
<li><a href="https://desktop.github.com/" target="_blank" rel="noopener noreferrer">GitHub Desktop</a> for more user friendly GUI.</li>
</ul>
</div></template>


